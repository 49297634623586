/* istanbul ignore file */
'use client'

import clsx from 'clsx'
import { LinkProps as NextLinkProps } from 'next/dist/client/link'
import NextLink from 'next/link'
import { FC, ReactNode } from 'react'
import { useTranslation } from '../../../lib/hooks'
import { withLocale } from '../../../lib/utils'
import { Icon, IconProps } from '../icon'
import styles from './link.module.css'

export interface Props extends Omit<NextLinkProps, 'href' | 'passHref'> {
    children?: ReactNode
    className?: string
    download?: string
    external?: boolean
    hideText?: boolean
    href: string
    iconName?: IconProps['name']
    iconSide?: 'left' | 'right'
    iconSize?: IconProps['size']
    noUnderline?: boolean
    target?: string
    underline?: boolean
    underlineOnHover?: boolean
}

const Link: FC<Props> = ({
    children,
    className,
    hideText,
    external,
    iconName,
    iconSide,
    iconSize,
    noUnderline,
    underline,
    underlineOnHover,
    ...props
}) => {
    const { locale } = useTranslation()

    const commonProps = {
        className: clsx(
            styles.root,
            iconName && styles.withIcon,
            underline && styles.underline,
            underlineOnHover && styles.underlineOnHover,
            noUnderline && styles.noUnderline,
            className
        ),
    }

    if (external) {
        return (
            <a
                {...commonProps}
                {...props}
                href={props.href}
                rel="noopener noreferrer"
                target="_blank"
            >
                {iconName && iconSide !== 'right' && (
                    <Icon
                        className={styles.icon}
                        name={iconName}
                        size={iconSize}
                    />
                )}
                {typeof children === 'string' ? (
                    <span
                        className={clsx(styles.text, hideText && styles.hidden)}
                    >
                        {children}
                    </span>
                ) : (
                    children
                )}
                {iconName && iconSide === 'right' && (
                    <Icon
                        className={styles.icon}
                        name={iconName}
                        size={iconSize}
                    />
                )}
            </a>
        )
    }

    return (
        <NextLink
            {...commonProps}
            {...props}
            href={
                props.href.startsWith('http') || props.locale === false
                    ? props.href
                    : withLocale(props.href, props.locale ?? locale)
            }
            locale={false}
            prefetch={false}
        >
            {iconName && iconSide !== 'right' && (
                <Icon className={styles.icon} name={iconName} size={iconSize} />
            )}
            {typeof children === 'string' ? (
                <span className={clsx(styles.text, hideText && styles.hidden)}>
                    {children}
                </span>
            ) : (
                children
            )}
            {iconName && iconSide === 'right' && (
                <Icon className={styles.icon} name={iconName} size={iconSize} />
            )}
        </NextLink>
    )
}

export default Link
