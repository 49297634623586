'use client'

import { useFormikContext } from 'formik'
import { ChangeEvent, FC, useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { InputFragment } from '../../../../../lib/graphql/generated/types'
import { useTranslation } from '../../../../../lib/hooks'
import { Button, ButtonTheme } from '../../../../base'
import {
    CompanionCreateDialog,
    CompanionCreateDialogValues,
} from '../../../../common'
import { CompanionInputGroup } from '../../../../common/companion/companionInputGroup'
import { Values } from '../selection'
import styles from './bookingOperationCompanions.module.css'

interface Props {
    inputFields: InputFragment[]
    maximumCompanions: number
    onAdd: (companionId: string) => void
    onRemove: (companionId: string) => void
    operationId: string
}

const BookingOperationCompanions: FC<Props> = props => {
    const { t } = useTranslation()
    const formik = useFormikContext<Values>()
    const companions = Object.values(formik.values.companions)

    const onCheckChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const companionId = e.currentTarget.value

            if (e.currentTarget.checked) {
                props.onAdd(companionId)
            } else {
                props.onRemove(companionId)
            }
        },
        [props]
    )

    const onCompanionCreateDialogSubmit = useCallback(
        async (values: CompanionCreateDialogValues) => {
            const id = uuidv4()

            formik.setFieldValue
                .call(undefined, 'companions', {
                    ...formik.values.companions,
                    [id]: {
                        id: id,
                        firstName: values.firstname ?? '',
                        lastName: values.lastname ?? '',
                        inputFieldValues: values,
                        operationIds: [],
                        persisted: false,
                    },
                })
                .catch(console.error)

            props.onAdd(id)
        },
        [formik.setFieldValue, formik.values.companions, props]
    )

    return (
        <div className={styles.root}>
            <CompanionInputGroup
                companions={companions}
                maximumCompanions={props.maximumCompanions}
                onChange={onCheckChange}
                operationId={props.operationId}
                operations={formik.values.operations[props.operationId]}
            />
            {
                <CompanionCreateDialog
                    inputFields={props.inputFields}
                    onSubmit={onCompanionCreateDialogSubmit}
                >
                    {open => (
                        <Button
                            className={styles.button}
                            disabled={
                                companions.length >= props.maximumCompanions
                            }
                            onClick={open}
                            theme={ButtonTheme.Transparent}
                        >
                            + {t('companion:create')}
                        </Button>
                    )}
                </CompanionCreateDialog>
            }
        </div>
    )
}

export default BookingOperationCompanions
