import { CompanionCreateInput } from '../../../../../../lib/graphql/generated/types'
import { encodeInputFieldValue } from '../../../utils'
import { CompanionValues } from '../../selection'

export const valuesToCompanions = (
    values: CompanionValues
): CompanionCreateInput[] =>
    Object.values(values)
        .filter(value => !value.persisted)
        .reduce(
            (acc: CompanionCreateInput[], companion) =>
                acc.concat({
                    id: companion.id,
                    inputFieldValues: Object.entries(
                        companion.inputFieldValues
                    ).map(([identifier, value]) => ({
                        identifier,
                        value: encodeInputFieldValue(value),
                    })),
                }),
            []
        )

export default valuesToCompanions
