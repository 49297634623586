'use client'

import { Form, Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { FC, ReactNode, useCallback, useState } from 'react'
import { InputFragment } from '../../../../lib/graphql/generated/types'
import { useTranslation, useYup } from '../../../../lib/hooks'
import { Button, ButtonGroup, ButtonTheme, Dialog } from '../../../base'
import InputField from '../../../event/registration/inputField'
import { getValidator } from '../../../event/registration/utils'
import styles from './companionCreateDialog.module.css'

export interface Values {
    [identifier: string]: string
}

interface Props {
    children: (open: () => void) => ReactNode
    inputFields: InputFragment[]
    onSubmit: (
        values: Values,
        formikHelpers: FormikHelpers<Values>
    ) => Promise<void>
}

const CompanionCreateDialog: FC<Props> = props => {
    const { t } = useTranslation()
    const yup = useYup()
    const [dialogOpen, setDialogOpen] = useState(false)

    const openDialog = useCallback(() => setDialogOpen(true), [])

    const closeDialog = useCallback(() => setDialogOpen(false), [])

    const onSubmit = useCallback(
        async (values: Values, formikHelpers: FormikHelpers<Values>) => {
            try {
                await props.onSubmit.call(undefined, values, formikHelpers)
                closeDialog()
            } catch (e) {
                /* istanbul ignore next */
                console.error(e)
            }
        },
        [closeDialog, props.onSubmit]
    )

    return (
        <>
            {props.children(openDialog)}
            <Formik<Values>
                initialValues={props.inputFields.reduce(
                    (acc, inputField) => ({
                        ...acc,
                        [inputField.identifier]: '',
                    }),
                    {}
                )}
                onSubmit={onSubmit}
                validationSchema={yup.object().shape({
                    ...props.inputFields.reduce(
                        (acc, inputField) => ({
                            ...acc,
                            [inputField.identifier]: getValidator(
                                yup,
                                inputField
                            ),
                        }),
                        {}
                    ),
                })}
            >
                {formik => (
                    <Dialog
                        onClose={closeDialog}
                        onClosed={formik.resetForm}
                        open={dialogOpen}
                        title={t('companion:create')}
                        small
                    >
                        <Form className={styles.form} noValidate>
                            <div className={styles.fields}>
                                {props.inputFields.map((inputField, k) => (
                                    <InputField
                                        key={k}
                                        inputField={inputField}
                                        name={inputField.identifier}
                                    />
                                ))}
                            </div>
                            <ButtonGroup>
                                <Button
                                    loading={formik.isSubmitting}
                                    type="submit"
                                >
                                    {t('common:save')}
                                </Button>
                                <Button
                                    disabled={formik.isSubmitting}
                                    onClick={closeDialog}
                                    theme={ButtonTheme.Cancel}
                                >
                                    {t('common:cancel')}
                                </Button>
                            </ButtonGroup>
                        </Form>
                    </Dialog>
                )}
            </Formik>
        </>
    )
}

export default CompanionCreateDialog
