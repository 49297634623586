'use client'

import { useFormikContext } from 'formik'
import { FC, useCallback } from 'react'
import { useCancelBookingMutation } from '../../../../lib/graphql/generated/hooks'
import { useRouter, useTranslation } from '../../../../lib/hooks'
import { useToken } from '../../../../lib/providers/tokenProvider/tokenProvider'
import { Button, ButtonTheme, FormErrorNotification } from '../../../base'
import BottomActionGroup from '../../../common/bottomActionGroup'

interface Props {
    bookingId?: string
    confirm?: boolean
    disabled?: boolean
    embedded?: boolean
    eventId: string
    eventKey: string
    onBack?: () => void
    onSubmit: () => void
    reset?: boolean
    submitButtonText: string
}

const Actions: FC<Props> = props => {
    const { t } = useTranslation()
    const router = useRouter()
    const { token } = useToken()
    const [, cancelBooking] = useCancelBookingMutation()
    const formik = useFormikContext<any>()

    const onCancel = useCallback(() => {
        // Cancel without waiting so it's instant for the user and don't show
        // errors
        if (token && props.bookingId) {
            cancelBooking({
                token,
                eventId: props.eventId,
                bookingId: props.bookingId,
            })
                .then(({ data, error }) => {
                    if (error) {
                        throw error
                    }

                    if (
                        data?.viewer?.event?.viewerParticipant?.cancelBooking
                            ?.inputError
                    ) {
                        throw Error(
                            data.viewer.event.viewerParticipant.cancelBooking
                                .inputError.message
                        )
                    }
                })
                .catch(e => {
                    console.error(e.message)
                })
        }

        /* istanbul ignore next */
        if (props.embedded) {
            window.parent.postMessage('close', '*')
        } else {
            router.replace(`/event/${props.eventKey}`)
        }
    }, [
        cancelBooking,
        props.bookingId,
        props.embedded,
        props.eventId,
        props.eventKey,
        router,
        token,
    ])

    const onReset = useCallback(
        () => formik.setFieldValue('operations', {}),
        [formik]
    )

    return (
        <BottomActionGroup>
            <Button
                loading={props.disabled}
                onClick={props.onSubmit}
                theme={
                    props.confirm ? ButtonTheme.Confirm : ButtonTheme.Primary
                }
            >
                {props.submitButtonText}
            </Button>
            <FormErrorNotification scrollToError />
            {props.reset && (
                <Button
                    disabled={
                        !Object.keys(formik.values).length || props.disabled
                    }
                    onClick={onReset}
                    theme={ButtonTheme.Secondary}
                >
                    {t('common:reset')}
                </Button>
            )}
            {props.onBack && (
                <Button
                    disabled={props.disabled}
                    onClick={props.onBack}
                    theme={ButtonTheme.Secondary}
                >
                    {t('common:back')}
                </Button>
            )}
            <Button
                disabled={props.disabled}
                onClick={onCancel}
                theme={ButtonTheme.Secondary}
            >
                {t('common:cancel')}
            </Button>
        </BottomActionGroup>
    )
}

export default Actions
