import { BookingOperationCreateInput } from '../../../../../../lib/graphql/generated/types'
import { encodeInputFieldValue } from '../../../utils'
import { OperationValues } from '../../selection'

export const valuesToBookingOperations = (
    values: OperationValues
): BookingOperationCreateInput[] => {
    return Object.entries(values).reduce(
        (
            acc: BookingOperationCreateInput[],
            [operationId, bookingOperations]
        ) => {
            return acc.concat(
                bookingOperations.map(bookingOperation => ({
                    operationId,
                    inputFieldValues:
                        bookingOperation.inputFieldValues &&
                        Object.entries(bookingOperation.inputFieldValues).map(
                            ([identifier, value]) => ({
                                identifier,
                                value: encodeInputFieldValue(value),
                            })
                        ),
                    groupBookingId: bookingOperation.groupBookingId,
                    newGroupBooking: bookingOperation.newGroupBooking,
                    companionId: bookingOperation.companionId,
                }))
            )
        },
        []
    )
}

export default valuesToBookingOperations
