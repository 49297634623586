'use client'

import { useCookies } from 'next-client-cookies'
import {
    createContext,
    FC,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react'
import { tokenKey } from '../../constants'

interface Context {
    setToken: (token?: string, persist?: boolean, expires?: Date) => void
    token: string | undefined
}

export const TokenContext = createContext<Context | undefined>(undefined)

interface Props {
    children: ReactNode
    token?: string
}

const TokenProvider: FC<Props> = props => {
    const cookies = useCookies()
    const [token, setTokenState] = useState<string | undefined>(
        cookies.get(tokenKey)
    )

    const setToken = useCallback(
        (newToken?: string, persist?: boolean, expires?: Date) => {
            if (newToken) {
                if (persist) {
                    try {
                        cookies.set(tokenKey, newToken, {
                            sameSite: 'Lax',
                            secure: true,
                            expires: expires,
                        })
                    } catch (error) {
                        console.error('Error setting cookie', error)
                    }
                }
                setTokenState(newToken)
            } else {
                cookies.remove(tokenKey)
                setTokenState(undefined)
            }
        },
        [cookies]
    )

    const value = useMemo(
        () => ({
            token: token,
            setToken: setToken,
        }),
        [token, setToken]
    )

    return (
        <TokenContext.Provider value={value}>
            {props.children}
        </TokenContext.Provider>
    )
}

export const useToken = () => {
    const context = useContext(TokenContext)
    if (!context) {
        throw new Error('useToken must be used within an TokenProvider')
    }
    return context
}

export default TokenProvider
