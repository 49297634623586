import {
    BookingFragment,
    CompanionFragment,
} from '../../../../../../lib/graphql/generated/types'
import { Values } from '../../selection'
import bookingToOperationValues from '../bookingToOperationValues'
import companionsToValues from '../companionsToValues'

export const bookingToValues = (
    booking?: BookingFragment | null | undefined,
    companions?: CompanionFragment[] | undefined
): Values => {
    return {
        companions: companions ? companionsToValues(companions) : {},
        operations: booking ? bookingToOperationValues(booking) : {},
    }
}

export default bookingToValues
