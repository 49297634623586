'use client'

import { Formik } from 'formik'
import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from 'react'
import {
    useConfirmBookingMutation,
    usePaymentGatewayUriQuery,
} from '../../../../lib/graphql/generated/hooks'
import {
    BookingFragment,
    BookingStatus,
    CompanionFragment,
    ParticipantFeatureSetting,
} from '../../../../lib/graphql/generated/types'
import {
    useNotification,
    useRouter,
    useTranslation,
    useYup,
} from '../../../../lib/hooks'
import { useToken } from '../../../../lib/providers/tokenProvider/tokenProvider'
import {
    AppNotificationType,
    RedirectStatus,
    RegisterQueryEvent,
} from '../../../../lib/types'
import { Container, Divider } from '../../../base'
import { BookingSummary, ParticipantSummary } from '../../../common'
import CompanionsSummary from '../../../common/companion/companionsSummary/companionsSummary'
import Actions from '../actions'
import Requirements from './requirements'
import styles from './review.module.css'
import ReviewSection from './reviewSection'
import companionsFromBooking from './utils/companionsFromBooking/companionsFromBooking'

interface Props {
    booking?: BookingFragment | null
    embedded?: boolean
    event: RegisterQueryEvent
    eventKey: string
    setStep: Dispatch<SetStateAction<number | undefined>>
    step: number
}

const Review: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const notification = useNotification()
    const router = useRouter()
    const yup = useYup()
    const { token } = useToken()

    const callbackUrls = useMemo(() => {
        if (
            typeof window === 'undefined' ||
            !props.booking?.bookingNr ||
            !props.booking?.payment?.onlinePaymentRequired ||
            !router.searchParams.has('step')
        ) {
            return
        }

        const url = new URL(window.location.href)
        const eventUrl = url.origin + url.pathname.replace('/register', '')
        const callbackUrl = url.origin + url.pathname
        const bookingNrParam = `bookingNr=${props.booking.bookingNr}`
        const stepParam = `step=${router.searchParams.get('step')}`

        return {
            successCallbackUri: `${eventUrl}?status=${RedirectStatus.PaymentSuccessful}`,
            cancelCallbackUri:
                `${callbackUrl}?${bookingNrParam}&${stepParam}` +
                `&status=${RedirectStatus.PaymentCanceled}`,
            failCallbackUri:
                `${callbackUrl}?${bookingNrParam}&${stepParam}` +
                `&status=${RedirectStatus.PaymentFailed}`,
        }
    }, [
        props.booking?.bookingNr,
        props.booking?.payment?.onlinePaymentRequired,
        router.searchParams,
    ])

    const [paymentGatewayUriQuery] = usePaymentGatewayUriQuery({
        variables: {
            token,
            locale,
            eventKeyOrId: props.eventKey,
            successCallbackUri: callbackUrls?.successCallbackUri ?? '',
            cancelCallbackUri: callbackUrls?.cancelCallbackUri ?? '',
            failCallbackUri: callbackUrls?.failCallbackUri ?? '',
        },
        pause: !callbackUrls,
    })

    const [, confirmBooking] = useConfirmBookingMutation()
    const redirectNotificationShown = useRef(false)

    const paymentRequired = props.booking?.payment?.onlinePaymentRequired

    const paymentGatewayUrl = useMemo(() => {
        if (!paymentRequired) {
            return
        }

        const bookings =
            paymentGatewayUriQuery.data?.viewer?.event?.viewerParticipant
                ?.bookings

        const bookingNrParam = router.searchParams.get('bookingNr')
        const booking = bookings?.find(b => b?.bookingNr === bookingNrParam)

        return booking?.payment?.gatewayUri
    }, [
        paymentGatewayUriQuery.data?.viewer?.event?.viewerParticipant?.bookings,
        paymentRequired,
        router.searchParams,
    ])

    useEffect(() => {
        const status = router.searchParams.get('status')

        if (redirectNotificationShown.current || !status) {
            return
        }

        redirectNotificationShown.current = true

        const params = new URLSearchParams(router.searchParams)
        params.delete('status')

        router.replaceSearchParams(params)

        if (status === RedirectStatus.PaymentCanceled) {
            notification.alert(t('registration:paymentCanceled'))
        } else if (status === RedirectStatus.PaymentFailed) {
            notification.alert(t('registration:paymentFailed'), {
                modal: true,
            })
        }
    }, [notification, router, t])

    const goToSelection = useCallback(() => {
        props.setStep.call(undefined, 1)
    }, [props.setStep])

    const goBack = useCallback(() => {
        props.setStep.call(undefined, prev => prev && prev - 1)
    }, [props.setStep])

    const onSubmit = useCallback(async () => {
        if (props.booking?.payment?.onlinePaymentRequired) {
            if (paymentGatewayUrl) {
                window.location.href = paymentGatewayUrl
            } else {
                notification.alert(t('common:error.internal'))
            }

            return
        }

        try {
            if (!token || !props.booking) {
                throw Error(t('common:error.internal'))
            }

            const { data, error } = await confirmBooking(
                {
                    token,
                    eventId: props.event.id,
                    bookingId: props.booking.id,
                },
                { additionalTypenames: ['Event'] }
            )

            if (error) {
                throw error
            }

            if (
                data?.viewer?.event?.viewerParticipant?.confirmBooking
                    ?.inputError
            ) {
                throw Error(
                    data.viewer.event.viewerParticipant.confirmBooking
                        .inputError.message
                )
            }

            const booking =
                data?.viewer?.event?.viewerParticipant?.confirmBooking.object

            if (
                booking?.bookingStatus !== BookingStatus.Confirmed &&
                booking?.bookingStatus !== BookingStatus.Pending &&
                booking?.bookingStatus !== BookingStatus.Requested
            ) {
                throw t('common:error.internal')
            }

            if (props.embedded) {
                router.replace('/embedded', {
                    searchParams: new URLSearchParams({
                        message: t('event:bookingConfirmation'),
                        type: AppNotificationType.Confirmation.toString(),
                    }),
                })
            } else {
                router.replace(`/event/${props.eventKey}`, {
                    searchParams: new URLSearchParams({
                        status: RedirectStatus.BookingSuccessful,
                    }),
                })
            }
        } catch (e) {
            notification.alert(e.message)
        }
    }, [
        confirmBooking,
        notification,
        paymentGatewayUrl,
        props.booking,
        props.embedded,
        props.event.id,
        props.eventKey,
        router,
        t,
        token,
    ])
    const companions: CompanionFragment[] = companionsFromBooking(props.booking)

    const requirements =
        props.event?.viewerRegistration?.participantRequirements || []

    return (
        <Formik
            initialValues={requirements.reduce(
                (acc: any, _requirement, i) => ({
                    ...acc,
                    [i.toString()]: false,
                }),
                {}
            )}
            onSubmit={onSubmit}
            validationSchema={yup.object().shape(
                requirements.reduce(
                    (acc: any, _requirement, i) => ({
                        ...acc,
                        [i.toString()]: yup
                            .boolean()
                            .oneOf([true], t('common:error.required')),
                    }),
                    {}
                )
            )}
            enableReinitialize
        >
            {formik => (
                <>
                    <Container className={styles.container}>
                        <ReviewSection
                            onEdit={
                                props.step === 3 ? goToSelection : undefined
                            }
                            title={t('registration:review.selection')}
                        >
                            {props.booking && (
                                <BookingSummary
                                    booking={props.booking}
                                    event={props.event}
                                    pricingTable
                                />
                            )}
                        </ReviewSection>
                        {companions.length > 0 && (
                            <>
                                <Divider />
                                <ReviewSection
                                    onEdit={
                                        props.step === 3
                                            ? goToSelection
                                            : undefined
                                    }
                                    title={t(
                                        companions.length > 1
                                            ? 'companion:companions'
                                            : 'companion:companion'
                                    )}
                                >
                                    <CompanionsSummary
                                        companions={companions}
                                    />
                                </ReviewSection>
                            </>
                        )}
                        <Divider />
                        <ReviewSection
                            onEdit={goBack}
                            title={t('registration:review.information')}
                        >
                            {props.booking?.participant && (
                                <ParticipantSummary
                                    bookingInputFieldValues={
                                        props.booking.inputFieldValues
                                    }
                                    participant={props.booking.participant}
                                />
                            )}
                            {props.event.viewerRegistration
                                ?.participantDefinition
                                .publicParticipantOptIn !==
                                ParticipantFeatureSetting.Disabled && (
                                <div className={styles.publicParticipant}>
                                    <strong>
                                        {t(
                                            'registration:visibleInParticipantList'
                                        )}
                                    </strong>
                                    <div>
                                        {props.booking?.participant.public
                                            ? t('common:yes')
                                            : t('common:no')}
                                    </div>
                                </div>
                            )}
                        </ReviewSection>
                        {requirements.length > 0 && (
                            <>
                                <Divider />
                                <Requirements requirements={requirements} />
                            </>
                        )}
                    </Container>
                    <Actions
                        bookingId={props.booking?.id}
                        disabled={
                            formik.isSubmitting ||
                            (paymentRequired && !paymentGatewayUrl)
                        }
                        embedded={props.embedded}
                        eventId={props.event.id}
                        eventKey={props.event.key}
                        onBack={goBack}
                        onSubmit={formik.submitForm}
                        submitButtonText={
                            paymentRequired
                                ? t('common:payment')
                                : t('common:confirm')
                        }
                        confirm
                    />
                </>
            )}
        </Formik>
    )
}

export default Review
