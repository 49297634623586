import {
    BookingFragment,
    CompanionFragment,
} from '../../../../../../lib/graphql/generated/types'

export const companionsFromBooking = (booking?: BookingFragment | null) =>
    booking
        ? booking.bookingOperations
              .map(bookingOperation => bookingOperation.companion)
              .filter(
                  (companion, i, self): companion is CompanionFragment =>
                      companion !== undefined &&
                      companion !== null &&
                      i === self.findIndex(other => other?.id === companion.id)
              )
        : []

export default companionsFromBooking
