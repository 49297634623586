import { OperationFragment } from '../../../../../../lib/graphql/generated/types'
import { decodeValueFromInputField } from '../../../utils'
import { OperationValue } from '../../selection'

export const operationToValue = (
    operation: OperationFragment,
    companionId?: string
): OperationValue => ({
    inputFieldValues: operation.inputFields.reduce(
        (acc, inputField) => ({
            ...acc,
            [inputField.identifier]: decodeValueFromInputField(inputField),
        }),
        {}
    ),
    companion: undefined,
    companionId: companionId,
})

export default operationToValue
