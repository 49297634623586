'use client'

import { ChangeEvent, FC } from 'react'
import { useTranslation } from '../../../../lib/hooks'
import { InputGroup } from '../../../base'
import {
    CompanionValue,
    OperationValue,
} from '../../../event/registration/selection/selection'
import CompanionInputGroupItem from '../companionInputGroupItem/companionInputGroupItem'

interface Props {
    companions: CompanionValue[]
    maximumCompanions: number
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    operationId: string
    operations: OperationValue[]
}

const CompanionInputGroup: FC<Props> = props => {
    const { t } = useTranslation()

    const name = `operations.${props.operationId}[0].companion`

    return (
        <InputGroup
            label={
                props.companions.length > 0
                    ? t('companion:companions') +
                      ` (max. ${props.maximumCompanions})`
                    : undefined
            }
            name={name}
            required
        >
            {props.companions.map(companion => (
                <CompanionInputGroupItem
                    key={companion.id}
                    checked={
                        props.operations.filter(
                            v => v.companionId === companion.id
                        ).length > 0
                    }
                    companion={companion}
                    onChange={props.onChange}
                    operationId={props.operationId}
                />
            ))}
        </InputGroup>
    )
}

export default CompanionInputGroup
