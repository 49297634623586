'use client'

import { Formik } from 'formik'
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'
import {
    useUpdateBookingMutation,
    useUpdateParticipantMutation,
} from '../../../../lib/graphql/generated/hooks'
import {
    BookingFragment,
    ParticipantFeatureSetting,
} from '../../../../lib/graphql/generated/types'
import { useNotification, useTranslation, useYup } from '../../../../lib/hooks'
import { useToken } from '../../../../lib/providers/tokenProvider/tokenProvider'
import { RegisterQueryEvent } from '../../../../lib/types'
import { Container } from '../../../base'
import { ParticipantSummary } from '../../../common'
import Actions from '../actions'
import InputField from '../inputField'
import {
    decodeValueFromInputField,
    decodeValueFromInputFieldValue,
    encodeInputFieldValue,
    getValidator,
} from '../utils'
import styles from './details.module.css'

interface Props {
    booking?: BookingFragment | null
    embedded?: boolean
    event: RegisterQueryEvent
    eventKey: string
    setStep: Dispatch<SetStateAction<number | undefined>>
    step: number
}

const Details: FC<Props> = props => {
    const { t } = useTranslation()
    const notification = useNotification()
    const yup = useYup()
    const { token } = useToken()
    const [, updateParticipant] = useUpdateParticipantMutation()
    const [, updateBooking] = useUpdateBookingMutation()

    const onBack = useCallback(() => {
        props.setStep.call(undefined, 1)
    }, [props.setStep])

    const participantId = props.event.viewerParticipant?.id

    const participantDefinitionInputFields = useMemo(
        () =>
            props.event.viewerRegistration?.participantDefinition.inputFields ??
            [],
        [props.event.viewerRegistration?.participantDefinition.inputFields]
    )

    const participantInputFields =
        props.booking?.participant.inputFieldValues ?? []

    const registrationInputFields = useMemo(
        () => props.event.viewerRegistration?.inputFields ?? [],
        [props.event.viewerRegistration?.inputFields]
    )

    const bookingInputFields = props.booking?.inputFieldValues ?? []

    const onSubmit = useCallback(
        async (values: any) => {
            try {
                if (!token || !participantId || !props.booking) {
                    throw Error(t('common:error.internal'))
                }

                const {
                    data: updateParticipantData,
                    error: updateParticipantError,
                } = await updateParticipant(
                    {
                        token,
                        eventId: props.event.id,
                        inputFieldValues: Object.entries(values)
                            .filter(([identifier, _]) =>
                                participantDefinitionInputFields.find(
                                    f => f.identifier === identifier
                                )
                            )
                            .map(([identifier, value]) => ({
                                identifier,
                                value: encodeInputFieldValue(value),
                            })),
                        public: values.public ?? false,
                    },
                    { additionalTypenames: ['Booking'] }
                )

                if (updateParticipantError) {
                    throw updateParticipantError
                }

                if (
                    updateParticipantData?.viewer?.event?.viewerParticipant
                        ?.updateParticipant?.inputError
                ) {
                    throw Error(
                        updateParticipantData.viewer.event.viewerParticipant
                            .updateParticipant.inputError.message
                    )
                }

                if (registrationInputFields.length) {
                    const { data, error } = await updateBooking(
                        {
                            token,
                            eventId: props.event.id,
                            bookingId: props.booking.id,
                            inputFieldValues: Object.entries(values)
                                .filter(([identifier, _]) =>
                                    registrationInputFields.find(
                                        f => f.identifier === identifier
                                    )
                                )
                                .map(([identifier, value]) => ({
                                    identifier,
                                    value: encodeInputFieldValue(value),
                                })),
                        },
                        { additionalTypenames: ['Booking'] }
                    )

                    if (error) {
                        throw error
                    }

                    if (
                        data?.viewer?.event?.viewerParticipant?.updateBooking
                            ?.inputError
                    ) {
                        throw Error(
                            data.viewer.event.viewerParticipant.updateBooking
                                .inputError.message
                        )
                    }
                }

                props.setStep.call(undefined, prev => prev && prev + 1)
            } catch (e) {
                notification.alert(e.message)
            }
        },
        [
            notification,
            participantDefinitionInputFields,
            participantId,
            props.booking,
            props.event.id,
            props.setStep,
            registrationInputFields,
            t,
            token,
            updateBooking,
            updateParticipant,
        ]
    )

    return (
        <Formik
            initialValues={{
                ...participantDefinitionInputFields.reduce(
                    (acc, participantDefinitionInputField) => {
                        const participantInputField =
                            participantInputFields.find(
                                f =>
                                    f.inputField.identifier ===
                                    participantDefinitionInputField.identifier
                            )

                        const value = participantInputField
                            ? decodeValueFromInputFieldValue(
                                  participantInputField
                              )
                            : decodeValueFromInputField(
                                  participantDefinitionInputField
                              )

                        return {
                            ...acc,
                            [participantDefinitionInputField.identifier]: value,
                        }
                    },
                    {}
                ),
                public: props.booking?.participant.public ?? false,
                ...registrationInputFields.reduce(
                    (acc, registrationInputField) => {
                        const bookingInputField = bookingInputFields.find(
                            f =>
                                f.inputField.identifier ===
                                registrationInputField.identifier
                        )

                        const value = bookingInputField
                            ? decodeValueFromInputFieldValue(bookingInputField)
                            : decodeValueFromInputField(registrationInputField)

                        return {
                            ...acc,
                            [registrationInputField.identifier]: value,
                        }
                    },
                    {}
                ),
            }}
            onSubmit={onSubmit}
            validationSchema={yup.object().shape({
                ...participantDefinitionInputFields.reduce(
                    (acc, inputField) => ({
                        ...acc,
                        [inputField.identifier]: getValidator(yup, inputField),
                    }),
                    {}
                ),
                ...registrationInputFields.reduce(
                    (acc, inputField) => ({
                        ...acc,
                        [inputField.identifier]: getValidator(yup, inputField),
                    }),
                    {}
                ),
            })}
            enableReinitialize
        >
            {formik => (
                <>
                    <Container className={styles.container}>
                        {participantDefinitionInputFields.length > 0 &&
                            participantDefinitionInputFields.map(inputField => {
                                return (
                                    <InputField
                                        key={inputField.identifier}
                                        inputField={inputField}
                                    />
                                )
                            })}
                        {participantDefinitionInputFields.length === 0 &&
                            props.booking?.participant && (
                                <ParticipantSummary
                                    participant={props.booking.participant}
                                />
                            )}
                        {ParticipantFeatureSetting.Disabled !==
                            props.event.viewerRegistration
                                ?.participantDefinition
                                .publicParticipantOptIn && (
                            <InputField
                                inputField={{
                                    label: t(
                                        'registration:visibleInParticipantList'
                                    ),
                                    identifier: 'public',
                                    __typename: 'CheckboxField',
                                    defaultCheckedValue:
                                        props.booking?.participant.public ??
                                        false,
                                    required: false,
                                }}
                            />
                        )}
                        {registrationInputFields.length > 0 &&
                            registrationInputFields.map(inputField => {
                                return (
                                    <InputField
                                        key={inputField.identifier}
                                        inputField={inputField}
                                    />
                                )
                            })}
                    </Container>
                    <Actions
                        bookingId={props.booking?.id}
                        disabled={formik.isSubmitting}
                        embedded={props.embedded}
                        eventId={props.event.id}
                        eventKey={props.event.key}
                        onBack={props.step > 1 ? onBack : undefined}
                        onSubmit={formik.submitForm}
                        submitButtonText={t('common:continue')}
                    />
                </>
            )}
        </Formik>
    )
}

export default Details
