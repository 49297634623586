import { OperationFragment } from '../../../../../../lib/graphql/generated/types'
import { OperationValue } from '../../selection'
import operationToValue from '../operationToValue'

export const companionOperationToValues = (
    operation: OperationFragment,
    companionIds: string[] = []
): OperationValue[] =>
    [undefined, ...companionIds].reduce(
        (acc: OperationValue[], companionId) =>
            acc.concat(operationToValue(operation, companionId)),
        []
    )

export default companionOperationToValues
