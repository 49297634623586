/* istanbul ignore file */
'use client'

import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
    useCreateBookingMutation,
    useParticipantLoginMutation,
    useRegisterQuery,
} from '../../../lib/graphql/generated/hooks'
import {
    BookingOperationCreateInput,
    BookingStatus,
    CompanionCreateInput,
} from '../../../lib/graphql/generated/types'
import {
    useNotification,
    useRouter,
    useTranslation,
    useViewerUser,
} from '../../../lib/hooks'
import { useToken } from '../../../lib/providers/tokenProvider/tokenProvider'
import { selectOperations } from '../../../lib/selectors'
import { AppNotificationType, EventQueryEvent } from '../../../lib/types'
import decodeTokenExpiration from '../../../lib/utils/decodeTokenExpiration'
import { Container, LoadingSpinner, ProgressBar } from '../../base'
import Details from './details'
import styles from './registration.module.css'
import Review from './review'
import Selection from './selection'

interface Props {
    embedded?: boolean
    event: EventQueryEvent
}

const Registration: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const notification = useNotification()
    const router = useRouter()
    const viewerUser = useViewerUser()
    const [, createBooking] = useCreateBookingMutation()
    const [, participantLogin] = useParticipantLoginMutation()
    const [step, setStep] = useState<number>()
    const { token, setToken } = useToken()

    const [registerQuery, executeRegisterQuery] = useRegisterQuery({
        variables: {
            token,
            locale,
            eventKeyOrId: props.event.key,
        },
    })

    const expirationNotificationShown = useRef(false)
    const createBookingCalled = useRef(false)

    const registrationEvent = registerQuery.data?.viewer?.event

    const singleOperation = useMemo(() => {
        const operations = selectOperations(registrationEvent)

        return operations.length === 1 && operations[0].inputFields.length === 0
            ? operations[0]
            : undefined
    }, [registrationEvent])

    const singleOperationBookable =
        singleOperation?.inputFields.length === 0 &&
        singleOperation?.viewerBookable &&
        singleOperation?.viewerBookable?.maximum === 1 &&
        singleOperation?.viewerBookable?.maximumCompanions === 0 &&
        !singleOperation.groupBookingDefinition

    const bookingNrParam = router.searchParams.get('bookingNr')
    const bookings = registrationEvent?.viewerParticipant?.bookings

    const booking =
        bookings?.find(b => b?.bookingNr === bookingNrParam) ??
        bookings?.find(b => b?.bookingStatus === BookingStatus.Reserved)

    useEffect(() => {
        const stepParam = Number(router.searchParams.get('step'))

        if (!step) {
            setStep(stepParam || 1)
        } else if (stepParam !== step) {
            const params = new URLSearchParams(router.searchParams)
            params.set('step', step.toString())

            router.replaceSearchParams(params)
        }
    }, [router, step])

    const _createBooking = useCallback(
        async (
            eventId: string,
            operations: BookingOperationCreateInput[],
            companions: CompanionCreateInput[]
        ) => {
            createBookingCalled.current = true

            const createBookingResult = await createBooking({
                token,
                locale,
                eventId,
                operations,
                companions,
            })

            if (createBookingResult.error) {
                throw createBookingResult.error
            }

            if (
                createBookingResult.data?.viewer?.event?.createBooking
                    ?.inputError
            ) {
                throw Error(
                    createBookingResult.data.viewer.event.createBooking
                        .inputError.message
                )
            }

            const accessIdentifier =
                createBookingResult.data?.viewer?.event?.createBooking?.object
                    ?.participant.accessIdentifier

            const bookingNr =
                createBookingResult.data?.viewer?.event?.createBooking?.object
                    ?.bookingNr

            if (!accessIdentifier || !bookingNr) {
                throw Error(t('common:error.internal'))
            }

            // Skip token creation when already authenticated
            if (viewerUser) {
                // Mutation doesn't trigger event refetch for some reason
                // (not even with "additionalTypenames: ['Booking']")
                executeRegisterQuery()

                return
            }

            const participantLoginResult = await participantLogin({
                accessIdentifier,
            })

            if (participantLoginResult.error) {
                throw participantLoginResult.error
            }

            const _token =
                participantLoginResult.data?.authentication
                    ?.createTokenByParticipant?.token
            const expires = decodeTokenExpiration(_token)

            if (!_token || !expires) {
                throw Error(t('common:error.internal'))
            }

            setToken(_token, !props.embedded, expires)

            router.refresh()
        },
        [
            createBooking,
            executeRegisterQuery,
            locale,
            participantLogin,
            router,
            t,
            token,
            viewerUser,
            props.embedded,
            setToken,
        ]
    )

    useEffect(() => {
        if (
            !createBookingCalled.current &&
            registrationEvent?.id &&
            !booking &&
            singleOperation &&
            singleOperationBookable &&
            typeof viewerUser !== 'undefined'
        ) {
            _createBooking(
                registrationEvent.id,
                [
                    {
                        operationId: singleOperation.id,
                        inputFieldValues: [],
                    },
                ],
                []
            ).catch(e => {
                if (props.embedded) {
                    router.replace(`/embedded`, {
                        searchParams: new URLSearchParams({
                            message: String(e.message),
                            type: String(AppNotificationType.Alert),
                        }),
                    })
                } else {
                    notification.alert(e.message)
                    router.replace(`/event/${router.params.eventKey}`)
                }
            })
        }
    }, [
        _createBooking,
        booking,
        notification,
        props.embedded,
        registrationEvent?.id,
        router,
        singleOperation,
        singleOperationBookable,
        viewerUser,
    ])

    useEffect(() => {
        if (booking && !bookingNrParam) {
            const params = new URLSearchParams(router.searchParams)
            params.set('bookingNr', booking.bookingNr)

            router.replaceSearchParams(params)
            setStep(singleOperationBookable ? 1 : 2)
        }
    }, [booking, bookingNrParam, router, singleOperationBookable])

    useEffect(() => {
        if (
            booking?.bookingStatus === BookingStatus.Expired &&
            !expirationNotificationShown.current
        ) {
            expirationNotificationShown.current = true

            notification.alert(t('registration:expiration.title'), {
                modal: true,
                modalButtonText: t('registration:expiration.button'),
                onClosed: () => {
                    router.replaceSearchParams()
                    router.reload()
                },
            })
        }
    }, [booking?.bookingStatus, notification, router, t])

    if (
        !step ||
        !registrationEvent ||
        (singleOperationBookable && !booking) ||
        registerQuery.fetching ||
        registerQuery.stale
    ) {
        return <LoadingSpinner centered large />
    }

    let steps = [
        t('registration:information.title'),
        t('registration:review.title'),
    ]

    if (!singleOperationBookable) {
        steps = [t('registration:selection.title'), ...steps]
    }

    let content = null

    if (singleOperationBookable) {
        content =
            step === 1 ? (
                <Details
                    booking={booking}
                    embedded={props.embedded}
                    event={registrationEvent}
                    eventKey={props.event.key}
                    setStep={setStep}
                    step={step}
                />
            ) : (
                <Review
                    booking={booking}
                    embedded={props.embedded}
                    event={registrationEvent}
                    eventKey={props.event.key}
                    setStep={setStep}
                    step={step}
                />
            )
    } else {
        switch (step) {
            case 1:
                content = (
                    <Selection
                        booking={booking}
                        createBooking={_createBooking}
                        embedded={props.embedded}
                        event={registrationEvent}
                        eventKey={props.event.key}
                        executeRegisterQuery={executeRegisterQuery}
                        setStep={setStep}
                    />
                )
                break
            case 2:
                content = (
                    <Details
                        booking={booking}
                        embedded={props.embedded}
                        event={registrationEvent}
                        eventKey={props.event.key}
                        setStep={setStep}
                        step={step}
                    />
                )
                break
            case 3:
                content = (
                    <Review
                        booking={booking}
                        embedded={props.embedded}
                        event={registrationEvent}
                        eventKey={props.event.key}
                        setStep={setStep}
                        step={step}
                    />
                )
                break
        }
    }

    return (
        <>
            <Container className={styles.container}>
                <ProgressBar onClick={setStep} step={step} steps={steps} />
            </Container>
            {content}
        </>
    )
}

export default Registration
