'use client'

import { useCookies } from 'next-client-cookies'
import { FC, useCallback, useMemo } from 'react'
import { tokenKey } from '../../../../lib/constants'
import {
    useConfirmBookingOperationsMutation,
    useDeleteBookingOperationsMutation,
} from '../../../../lib/graphql/generated/hooks'
import {
    BookingFragment,
    BookingOperationStatus,
    BookingStatus,
} from '../../../../lib/graphql/generated/types'
import { useNotification, useTranslation } from '../../../../lib/hooks'
import { BookingsQueryEvent, RegisterQueryEvent } from '../../../../lib/types'
import {
    Badge,
    Button,
    ButtonTheme,
    DateFormat,
    FormattedDate,
    Text,
} from '../../../base'
import BookingSummaryBookingOperation from '../bookingSummaryBookingOperation'
import styles from './bookingSummaryOperation.module.css'

interface Props
    extends Pick<BookingFragment, 'bookingStatus'>,
        Pick<
            BookingFragment['groupedBookingOperations'][number],
            | 'bookingOperations'
            | 'bookingOperationStatus'
            | 'count'
            | 'operation'
        > {
    event: BookingsQueryEvent | RegisterQueryEvent
    loading?: boolean
    participantId?: string
}

const BookingSummaryOperation: FC<Props> = props => {
    const { t } = useTranslation()
    const notification = useNotification()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const [confirmBookingOperationsMutation, confirmBookingOperations] =
        useConfirmBookingOperationsMutation()
    const [deleteBookingOperationsMutation, deleteBookingOperations] =
        useDeleteBookingOperationsMutation()

    const parentTitle = useMemo(() => {
        if (props.operation.parentId === props.event.id) {
            return
        }

        const parent =
            props.event.subEvents?.find(
                subEvent => subEvent.id === props.operation.parentId
            ) ??
            props.event.services?.find(
                service => service.id === props.operation.parentId
            )

        return parent?.title
    }, [
        props.event.id,
        props.event.services,
        props.event.subEvents,
        props.operation.parentId,
    ])

    const badge = useMemo(() => {
        if (
            props.bookingStatus === BookingStatus.Pending ||
            props.bookingStatus === BookingStatus.Requested
        ) {
            return (
                <Badge className={styles.pending}>{t('common:pending')}</Badge>
            )
        }

        if (props.bookingStatus !== BookingStatus.Confirmed) {
            return null
        }

        switch (props.bookingOperationStatus) {
            case BookingOperationStatus.Waiting:
                return (
                    <Badge className={styles.pending}>
                        {t('common:waitinglist')}
                    </Badge>
                )
            case BookingOperationStatus.Invited:
                return (
                    <Badge className={styles.pending}>
                        {t('common:invited')}
                    </Badge>
                )
            case BookingOperationStatus.Confirmed:
                return (
                    <Badge className={styles.confirmed}>
                        {t('common:confirmed')}
                    </Badge>
                )
            case BookingOperationStatus.Deleted:
                return (
                    <Badge className={styles.declined}>
                        {t('common:declined')}
                    </Badge>
                )
            default:
                return null
        }
    }, [props.bookingOperationStatus, props.bookingStatus, t])

    const onInvitationConfirm = useCallback(async () => {
        if (!token || !props.bookingOperations.length) {
            notification.alert(t('common:error.internal'), { modal: true })
            return
        }

        try {
            const { error } = await confirmBookingOperations(
                {
                    token,
                    eventId: props.event.id,
                    bookingOperationIds: props.bookingOperations.map(
                        bookingOperation => bookingOperation.id
                    ),
                },
                { additionalTypenames: ['Booking'] }
            )

            if (error) {
                throw error
            }
        } catch (e) {
            console.error(e)
            notification.alert(t('common:error.internal'), { modal: true })
        }
    }, [
        confirmBookingOperations,
        notification,
        props.bookingOperations,
        props.event.id,
        t,
        token,
    ])

    const onInvitationDecline = useCallback(async () => {
        if (!token) {
            notification.alert(t('common:error.internal'), { modal: true })
            return
        }

        try {
            const { error } = await deleteBookingOperations(
                {
                    token,
                    eventId: props.event.id,
                    bookingOperationIds: props.bookingOperations.map(
                        bookingOperation => bookingOperation.id
                    ),
                },
                { additionalTypenames: ['Booking'] }
            )

            if (error) {
                throw error
            }
        } catch (e) {
            console.error(e)
            notification.alert(t('common:error.internal'), { modal: true })
        }
    }, [
        deleteBookingOperations,
        notification,
        props.bookingOperations,
        props.event.id,
        t,
        token,
    ])

    const loading =
        props.loading ||
        confirmBookingOperationsMutation.fetching ||
        deleteBookingOperationsMutation.fetching

    const companions = props.bookingOperations
        .map(bookingOperation => bookingOperation.companion)
        .filter(companion => companion !== undefined && companion !== null)

    const groupedCompanions = props.bookingOperations.reduce(
        (acc, bookingOperation) =>
            acc && bookingOperation.inputFieldValues.length <= 0,
        true
    )

    return (
        <li className={styles.root}>
            <div className={styles.header}>
                <Text className={styles.title} element="h3" type="h6">
                    {parentTitle && (
                        <span>
                            {parentTitle}
                            {props.operation.title && ' / '}
                        </span>
                    )}
                    {props.operation.title && (
                        <>
                            <span>
                                {props.count > 1 && `${props.count} × `}
                                {props.operation.title}
                            </span>
                        </>
                    )}
                    {badge}
                </Text>
                {props.operation.operationDates.length > 0 && (
                    <Text element="div" type="smaller">
                        {props.operation.operationDates.map(
                            ({ startDate, endDate }, i) => (
                                <FormattedDate
                                    key={i}
                                    className={styles.date}
                                    endDate={endDate}
                                    format={DateFormat.Medium}
                                    startDate={startDate}
                                    timezone={props.event.timezone.name}
                                    withTime
                                />
                            )
                        )}
                    </Text>
                )}
                {props.bookingOperationStatus ===
                    BookingOperationStatus.Invited && (
                    <div className={styles.actions}>
                        <Button
                            className={styles.button}
                            disabled={loading}
                            iconName="check-circle"
                            onClick={onInvitationConfirm}
                            theme={ButtonTheme.Transparent}
                        >
                            {t('common:confirm')}
                        </Button>
                        <Button
                            className={styles.button}
                            disabled={loading}
                            iconName="prohibit"
                            onClick={onInvitationDecline}
                            theme={ButtonTheme.Transparent}
                        >
                            {t('common:decline')}
                        </Button>
                    </div>
                )}
                {groupedCompanions && companions.length > 0 && (
                    <div className={styles.companions}>
                        <strong>
                            {t(
                                companions.length > 1
                                    ? 'companion:companions'
                                    : 'companion:companion'
                            )}
                        </strong>
                        <ul>
                            {companions.map(companion => (
                                <li key={companion.id}>
                                    {companion.firstName +
                                        ' ' +
                                        companion.lastName}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            {props.bookingOperations.map((bookingOperation, i) => (
                <BookingSummaryBookingOperation
                    key={i}
                    bookingOperation={bookingOperation}
                    bookingStatus={props.bookingStatus}
                    eventId={props.event.id}
                    groupBookingDefinition={
                        props.operation.groupBookingDefinition
                    }
                    groupedCompanions={groupedCompanions}
                    loading={props.loading}
                    participantId={props.participantId}
                />
            ))}
        </li>
    )
}

export default BookingSummaryOperation
