import { InputValueFragment } from '../../../../../../lib/graphql/generated/types'
import { InputFieldValueValues } from '../../selection'

export const inputFieldValuesToValues = (
    inputFieldValues: InputValueFragment[]
): InputFieldValueValues =>
    inputFieldValues.reduce((_acc, inputFieldValue) => {
        let value

        switch (inputFieldValue.__typename) {
            case 'BooleanInputFieldValue':
                value = inputFieldValue.selected
                break
            case 'DateInputFieldValue':
                value = inputFieldValue.date ?? ''
                break
            case 'ListStringInputFieldValue':
                value = inputFieldValue.values ?? []
                break
            case 'StringInputFieldValue':
                value = inputFieldValue.value ?? ''
                break
        }

        return {
            ..._acc,
            [inputFieldValue.inputField.identifier]: value,
        }
    }, {})

export default inputFieldValuesToValues
