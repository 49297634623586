import { FC } from 'react'
import { AdditionalFieldsFragment } from '../../../lib/graphql/generated/types'
import { FormattedHtml, Link, Text } from '../../base'
import styles from './additionalField.module.css'

interface Props {
    field: AdditionalFieldsFragment
}

const AdditionalField: FC<Props> = props => {
    let content

    switch (props.field.__typename) {
        case 'AdditionalLinkField':
            if (!props.field.url || !props.field.text) {
                return null
            }

            content = (
                <div className={styles.content}>
                    <Link href={props.field.url} target={props.field.target}>
                        {props.field.text}
                    </Link>
                </div>
            )

            break
        case 'AdditionalEmailField':
            content = (
                <div className={styles.content}>
                    <Link
                        href={`mailto:${props.field.email}`}
                        iconName="envelope-simple"
                        external
                    >
                        {props.field.email}
                    </Link>
                </div>
            )

            break
        case 'AdditionalMultilineField':
            if (!props.field.text) {
                return null
            }

            content = (
                <div className={styles.content}>
                    <FormattedHtml html={props.field.text} />
                </div>
            )

            break
        case 'AdditionalImageListField':
            content = props.field.images.map((image, i) => (
                <figure key={i} className={styles.content}>
                    <img
                        alt={image.caption || image.title || ''}
                        className={styles.image}
                        src={image.thumbnail?.resource}
                    />
                    {image.caption && <figcaption>{image.caption}</figcaption>}
                </figure>
            ))

            break
        case 'AdditionalFileListField':
            content = (
                <ul className={styles.content}>
                    {props.field.files.map(file => (
                        <li key={file.title}>
                            {file.caption && <strong>{file.caption}</strong>}
                            <Link
                                href={file.resource}
                                iconName="file"
                                target="_blank"
                            >
                                {file.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            )

            break
        default:
            console.warn(
                'Unsupported additional field type:',
                props.field.__typename
            )

            return null
    }

    return (
        <>
            <Text element="h2" type="h4">
                {props.field.label}
            </Text>
            {content}
        </>
    )
}

export default AdditionalField
