import { FC } from 'react'
import { CompanionFragment } from '../../../../lib/graphql/generated/types'
import FormattedInputFieldValue from '../../formattedInputFieldValue'
import styles from './companionsSummary.module.css'

interface Props {
    companions: CompanionFragment[]
}

const CompanionsSummary: FC<Props> = props => (
    <div className={styles.root}>
        {props.companions.map(companion => (
            <div key={companion?.id} className={styles.item}>
                {companion?.inputFieldValues.map(inputFieldValue => (
                    <FormattedInputFieldValue
                        key={inputFieldValue.inputField.identifier}
                        inputFieldValue={inputFieldValue}
                    />
                ))}
            </div>
        ))}
    </div>
)

export default CompanionsSummary
