import { BookingFragment } from '../../../../../../lib/graphql/generated/types'
import { OperationValues } from '../../selection'
import inputFieldValuesToValues from '../inputFieldValuesToValues'

export const bookingToOperationValues = (
    booking: BookingFragment
): OperationValues =>
    booking.bookingOperations.reduce(
        (acc: OperationValues, bookingOperation) => {
            const bookingOperations = acc[bookingOperation.operation.id] ?? []

            const newGroupBooking =
                bookingOperation.groupBooking?.primaryParticipant &&
                bookingOperation.groupBooking.participantCount === 1
                    ? {
                          name: bookingOperation.groupBooking.name,
                          description:
                              bookingOperation.groupBooking.description,
                          public: bookingOperation.groupBooking.public,
                      }
                    : undefined

            bookingOperations.push({
                inputFieldValues: inputFieldValuesToValues(
                    bookingOperation.inputFieldValues
                ),
                groupBookingId: newGroupBooking
                    ? undefined
                    : bookingOperation.groupBooking?.id,
                newGroupBooking,
                companionId: bookingOperation.companion?.id,
                companion: undefined,
            })

            return {
                ...acc,
                [bookingOperation.operation.id]: bookingOperations,
            }
        },
        {}
    )

export default bookingToOperationValues
